import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { Footer, NavBar } from "./components/common";
import { Global } from "./styled";
import { PreLoader } from "./assets";
import Cookies from "./components/cookies-consent/cookies";
import { getCookie } from "./utils";

const AboutUsPage = lazy(() => import( "./pages/AboutUsPage"));
const LandingPage = lazy(() => import( "./pages/LandingPage"));
const ServicesPage = lazy(() => import( "./pages/ServicesPage"));
const SupportPage = lazy(() => import( "./pages/SupportPage"));
const CareerPage = lazy(() => import( "./pages/CareerPage"));
const NotFoundPage = lazy(() => import( "./pages/NotFoundPage"));
const JobApplication = lazy(() => import( "./pages/JobApplication"));
const ProfessionalPage = lazy(() => import( "./pages/ProfessionalPage"));
const TermsPage = lazy(() => import( "./pages/TermsPage"));
const PoliciesPage = lazy(() => import( "./pages/PoliciesPage"));




function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_TRACK_ID);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(pathname);
    }, [pathname]);

    return null;
}


function App() {

    const [isCookieSet, setIsCookieSet] = useState(false);
    const cookie = getCookie('consentCookieClosed');

    return (
        <Router>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Suspense fallback={
                <div className="h-screen w-full flex items-center justify-center">
                    <img src={PreLoader} alt=""/>
                </div>
            }>
                <ScrollToTop/>
                <NavBar/>
                <Global/>
                {!isCookieSet && !cookie ? <Cookies setIsCookieSet={setIsCookieSet}/> : null}
                <Switch>
                    <Route path="/" exact component={LandingPage}/>
                    <Route path="/about-us" component={AboutUsPage}/>
                    <Route path="/services" exact component={ServicesPage}/>
                    <Route path="/careers" component={CareerPage}/>
                    <Route path="/support" component={SupportPage}/>
                    <Route path="/services/:name" exact component={ProfessionalPage}/>
                    <Route path="/job-application" exact component={JobApplication} />
                    <Route path="/terms" component={TermsPage} exact />
                    <Route path="/policies" component={PoliciesPage} exact />
                    <Route path="*" component={NotFoundPage} />
                </Switch>
                <Footer/>
            </Suspense>
        </Router>
    );
}

export default App;
