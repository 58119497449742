import { useState } from "react";
import styled, {keyframes} from "styled-components";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useMenu } from "../../context";
import {
    Frown, Cool, Love, Disappointed
} from '../../assets/index';
import Loader from "./Loader";

const moods = [
    {
        name: 'Love',
        image: Love
    },
    {
        name: 'Cool',
        image: Cool
    },
    {
        name: 'Frown',
        image: Frown
    },
    {
        name: 'Disappointed',
        image: Disappointed
    }
];

const feedbackTypes = ['Suggestion', 'Complaint', 'Compliment'];

const pulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }
    80% {
        box-shadow: 0 0 0 30px rgba(37, 211, 102, 0);
    }
`;

const StyledFeedbackFormWrapper = styled.div`
    @media screen and (max-width: 1024px) {
        background: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
    }
`;

const StyledFeedbackIcon = styled.div`
    border-radius: 20px;
    cursor: pointer;
    box-sizing: content-box;
    animation-name: ${pulse};
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;

    span {
        font-family: "Poppins", sans-serif;
        font-size: 10px;
        line-height: 10px;
        color: #FF1D1D;
    }
`;

const StyledFeedbackForm = styled.div`
    width: 411px;
    height: 75vh;
    background: #FFFFFF;
    box-shadow: 2px 1px 12px #F2F2F2;
    z-index: 99999;
    .header {
        height: 30%;
        background: #000066;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        .title {
            font-family: "Poppins", sans-serif;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.8px;
            color: #FFFFFF;
        }
        .tagline {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.8px;
            color: rgba(255, 255, 255, 0.87);
            text-align: center;
        }
    }
    .content {
        height: 70%;
        .form-header {
            height: 30%;
        }
        .title {
            font-size: 18px;
            line-height: 34px;
            color: #000000;
        }
        .tagline {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.8px;
            color: rgba(64, 64, 64, 0.87);
        }
        .moods {
            img {
                cursor: pointer;
                &.selected {
                    border: 2px solid #000066;
                    padding: 5px;
                    border-radius: 50%;
                }
            }
        }
        .submit {
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid rgba(255, 29, 29, 0.5);
            border-radius: 20px;
            color: #FF1D1D;
            font-weight: 500;
            font-size: 18px;
        }
        textarea {
            background: #FFFFFF;
            border: 1px solid #979797;
            box-sizing: border-box;
            border-radius: 5px;
            width: 100%;
            height: 30%;
            resize: none;
            color: rgba(64, 64, 64, 0.87);
        }
        .btn-wrapper {
                span {
                    font-family: "Poppins", sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: #4A4A4A !important;
                }
            }
        .comment-type {
            width: 100%;
            button {
                width: 30%;
                height: 46px;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                line-height: 10px;
                border: 0.5px solid #C7C7C7;
                box-sizing: border-box;
                border-radius: 4px;
                color: #4A4A4A;
                &.selected {
                    background: #000066;
                    color: #FFFFFF;
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        width: 80vw;
        .header {
            height: 25%;
            font-size: 14px;
            padding-top: 10px;
            padding-bottom: 10px;
            .title {
                font-size: 14px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .tagline {
                font-size: 10px;
                line-height: 10px;
            }
        }
        .content {
            padding: 15px 20px;
            .title {
                font-size: 14px;
                line-height: 18px;
            }
            .tagline {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 10px;
                text-align: center;
            }
            .moods {
                img {
                    width: 25px;
                    height: 25px;
                    padding: 2px;
                }
            }
            .comment-type {
                .btn-wrapper {
                    span {
                        font-size: 14px;
                    }
                }
                button {
                    font-size: 9px;
                    height: 30px;
                }
            }
            .submit, textarea {
                margin-top: 5px;
            }
            textarea {
                min-height: 50px;
                font-size: 10px;
            }
        }
        
  }
`;

const FeedbackIcon = ({handleShowForm}) => {
    const [, setIsMenuOpen] = useMenu();
    return (
        <StyledFeedbackIcon className="flex flex-col ml-4 justify-center items-center w-10 h-10" onClick={() => {
            if (window.innerWidth <= 1024) {
                setIsMenuOpen(false);
            }
            handleShowForm(true)
        }}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#FF0000"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M20.0008 9.33331C14.8462 9.33331 10.6675 13.512 10.6675 18.6666V19C10.6675 24.3387 14.9954 28.6666 20.3341 28.6666H22.2833C22.6134 28.6666 22.7785 28.6666 22.942 28.672C24.0756 28.7091 25.1947 28.9388 26.2514 29.3513C26.4038 29.4108 26.5555 29.4758 26.8589 29.6058L29.3341 30.6666V28.6666V18.6666C29.3341 13.512 25.1555 9.33331 20.0008 9.33331ZM13.5358 23.4777C13.5621 23.5024 13.6214 23.558 13.6552 23.5876C13.7295 23.6527 13.8363 23.7414 13.9755 23.8458C14.2538 24.0545 14.6627 24.3264 15.2023 24.5963C16.2839 25.137 17.8853 25.6666 20.0005 25.6666C22.1157 25.6666 23.7171 25.137 24.7986 24.5963C25.3383 24.3264 25.7472 24.0545 26.0255 23.8458C26.1647 23.7414 26.2714 23.6527 26.3457 23.5876L26.4719 23.4714C26.7322 23.211 26.7322 22.7889 26.4719 22.5286C26.2117 22.2684 25.7899 22.2682 25.5295 22.5281L25.4677 22.5842C25.417 22.6285 25.3363 22.696 25.2255 22.7791C25.0038 22.9454 24.6627 23.1735 24.2023 23.4037C23.2839 23.8629 21.8853 24.3333 20.0005 24.3333C18.1157 24.3333 16.7171 23.8629 15.7986 23.4037C15.3383 23.1735 14.9972 22.9454 14.7755 22.7791C14.6647 22.696 14.5839 22.6285 14.5332 22.5842L14.4702 22.5269C14.2097 22.2687 13.7887 22.2689 13.5291 22.5286C13.2687 22.7889 13.2687 23.211 13.5291 23.4714L13.5358 23.4777Z" fill="white"/>
                <path d="M16.4287 16.7591C16.4287 16.6687 16.4465 16.5792 16.4811 16.4956C16.5157 16.4121 16.5665 16.3362 16.6304 16.2722C16.6943 16.2083 16.7702 16.1576 16.8538 16.123C16.9373 16.0884 17.0269 16.0706 17.1173 16.0706C17.2077 16.0706 17.2973 16.0884 17.3808 16.123C17.4644 16.1576 17.5403 16.2083 17.6042 16.2722C17.6681 16.3362 17.7189 16.4121 17.7535 16.4956C17.7881 16.5792 17.8059 16.6687 17.8059 16.7591V19.5135C17.8059 19.6961 17.7333 19.8713 17.6042 20.0004C17.4751 20.1295 17.2999 20.2021 17.1173 20.2021C16.9347 20.2021 16.7595 20.1295 16.6304 20.0004C16.5013 19.8713 16.4287 19.6961 16.4287 19.5135V16.7591Z" fill="#FF0000"/>
                <path d="M18.2651 16.6825V19.1752C18.2651 19.3458 18.3125 19.513 18.4022 19.6582C18.4919 19.8033 18.6202 19.9206 18.7728 19.9969L18.7958 20.0084C19.0505 20.1357 19.3314 20.202 19.6161 20.2021H22.1023C22.3147 20.2022 22.5204 20.1287 22.6847 19.9941C22.8489 19.8595 22.9614 19.6721 23.003 19.4639L23.5538 16.7096C23.5805 16.5764 23.5772 16.4389 23.5443 16.3072C23.5114 16.1754 23.4497 16.0526 23.3636 15.9475C23.2774 15.8425 23.1691 15.7579 23.0464 15.6998C22.9236 15.6417 22.7895 15.6115 22.6537 15.6115H21.0194V13.7753C21.0194 13.5318 20.9227 13.2982 20.7505 13.1261C20.5784 12.9539 20.3448 12.8571 20.1013 12.8571C19.9796 12.8571 19.8628 12.9055 19.7767 12.9916C19.6906 13.0777 19.6423 13.1945 19.6423 13.3162V13.6224C19.6423 14.0197 19.5134 14.4063 19.275 14.7241L18.6324 15.5807C18.394 15.8986 18.2651 16.2852 18.2651 16.6825V16.6825Z" fill="#FF0000"/>
            </svg>

        </StyledFeedbackIcon>
    );
};

const FeedbackForm = ({handleShowForm}) => {
    const [feedback, setFeedback] = useState({
        mood: moods[0].name,
        type: 'Suggestion',
        comment: ''
    });
    const [loading, setLoading] = useState(false);

    const handleFeedbackChange = (name, value) => {
        const newFeedback = {...feedback};
        newFeedback[name] = value;
        setFeedback(newFeedback);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post('https://prod-98.westeurope.logic.azure.com/workflows/c5eb5505db8c4d2fb5be06c35e78da25/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ysqkmgtoA9r5s8KcJpONyhvnIKa92SXr5igqfLZc0DQ', feedback)
        .then(() => {
            setLoading(false);
            toast.success('Your feedback is well received, thank you!');
            handleShowForm(false);
        })
        .catch(() => {
            setLoading(false)
            toast.error('We encountered an error sending your feedback, please try again later!')
        });
    }

    return (
        <StyledFeedbackFormWrapper className="fixed top-0 left-0">
            <StyledFeedbackForm className="fixed top-20 right-5">
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute top-5 right-5 cursor-pointer"
                    onClick={() => handleShowForm(false)}
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z" fill="white"/>
                </svg>

                <div className="header px-6 py-8 flex flex-col justify-center items-center">
                    <p className="mb-4 title">Share your feedback!</p>
                    <p className="tagline">Are you happy with your experience so far? Please share your thoughts with us, it’s invaluable to improving our services.</p>
                </div>
                <form onSubmit={handleSubmit} className="px-6 pt-0 pb-4 flex flex-col items-center justify-around content">
                    <div className="flex flex-col items-center justify-between form-header">
                        <span className="title">Rate your experience!</span>
                        <span className="mb-1 tagline">What is your opinion about this website?</span>
                        <div className="flex flex-row justify-center items-center moods">
                            {
                                moods.map((mood) => (
                                    <img
                                        src={mood.image}
                                        alt={mood.name}
                                        key={mood.name}
                                        className={`mx-2 ${feedback.mood === mood.name ? 'selected' : ''}`}
                                        onClick={() => handleFeedbackChange('mood', mood.name)}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <textarea
                        required
                        placeholder="Kindly leave a comment here"
                        className="p-2"
                        onChange={(event) => handleFeedbackChange('comment', event.target.value)}
                    />
                    <div className="w-full flex flex-col justify-center items-start btn-wrapper">
                        <span>Send comment as?</span>
                        <div className="comment-type flex flex-row justify-between items-center">
                            {
                                feedbackTypes.map((type) => (
                                    <button
                                        className={type === feedback.type ? 'selected' : ''}
                                        onClick={() => handleFeedbackChange('type', type)}
                                        type="button"
                                        key={type}
                                    >
                                        {type}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                    {loading ? <Loader /> : <button className="submit">Submit</button>}
                </form>
            </StyledFeedbackForm>
        </StyledFeedbackFormWrapper>
    )
}


const Feedback = () => {
    const [showForm, setShowForm] = useState(false);
        return (
            <>

                <FeedbackIcon handleShowForm={setShowForm} />
                {showForm && <FeedbackForm handleShowForm={setShowForm} />}
            </>
        )
};

export default Feedback;
