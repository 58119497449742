import styled from "styled-components";

export const RegularButton = styled.button`
  color: ${({color}) => color || "#0d0d0d"};
  font-weight: 400;
  background-color: ${(props) => props.bg || "#ffffff"};
  border-radius: 5px;
  font-size: ${(props) => props.fontSize || "13px"};
  line-height: 26px;
  width: ${(props) => props.width || "83px"};
  height: ${(props) => props.height || "33px"};
  transition: all 0.2s ease-in-out;
  &:hover {
    margin-top: ${(props) => props.isLoading ? 0 : "-5px"};
    box-shadow:  ${(props) => props.isLoading ? '0 0 0 transparent' : "0 4px 25px rgba(0, 0, 0, 0.2)"};
  }
`;
