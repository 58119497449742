import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Logo,
  Mail,
  Phone,
  Location,
} from "../../assets";
import { IconWrapper } from ".";
import {socialLinks} from "./NavBar";

const StyledFooter = styled.footer`
  color: #ffffff;
  background: #000066;
  min-height: 450px;
  .copywrite {
    height: 50px;
    font-size: 0.9vw;
    border-top: 0.1px solid #303083;
    @media screen and (max-width: 972px) {
      font-size: 10px;
    }
  }
`;

const LogoImage = styled.img`
  object-fit: contain;
  width: 150px;
  height: 80px;
`;

const SubText = styled.p`
  font-size: 1vw;
  width: 300px;
  @media screen and (max-width: 972px) {
    font-size: 11px;
    width: 250px;
    text-align: center;
  }
`;

const Header = styled.h1`
  font-size: 24px;
  line-height: 21.09px;
  font-weight: 500;
`;
export const ResponsiveDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 350px;
  &.links-wrapper {
    ul {
      margin-left: 0;
      padding-left: 0;
    }
  }
  @media screen and (max-width: 972px) {
    min-width: 300px;
    align-items: center;
  }
`;

const Ul = styled.ul`
  list-style: none;
`;

const Li = styled.li`
  font-size: 1vw;
  cursor: pointer;
  @media screen and (max-width: 972px) {
    font-size: 11px;
  }
`;
const P = styled.p`
  font-size: 1vw;
  width: 260px;
  @media screen and (max-width: 972px) {
    font-size: 11px;
  }
`;
const IconsDiv = styled.div`
  @media screen and (max-width: 972px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
`;

const FooterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  @media screen and (max-width: 972px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .details {
    p {
      flex-grow: 1;
    }
  }

  @media screen and (max-width: 972px) {
    .details {
      padding-left: 20%;
    }
  }
`;

function Footer() {
  const {pathname} = useLocation();
  if (pathname === '/terms' && (window.location !== window.parent.location)) {
    return null;
  }

  return (
    <StyledFooter className="flex flex-col justify-between">
      <FooterDiv className="px-28 h-full py-10 flex footer-div">
        <ResponsiveDiv className="space-y-3">
          <LogoImage src={Logo} alt="footerlogo" />
          <SubText className="font-normal">
            Our goal is to ensure that our clients are making good I.T. decisions, and are realising business value from their I.T. investments.
          </SubText>
          <IconsDiv className="md:flex space-x-3">
          {
            socialLinks.map((socialLink, index) => {
              return (
                <IconWrapper key={`${index}-footer`} color="rgba(255, 255, 255, 0.1)" link={socialLink.link}>
                  <socialLink.component className="w-5 h-5" color="white" />
                </IconWrapper>
              )
            })
          }
          </IconsDiv>
        </ResponsiveDiv>
        <ResponsiveDiv className="py-5 links-wrapper">
          <Header>Quick Links</Header>
          <Ul className="space-y-3 mt-6 ml-14 md:ml-0">
            <Li><a href="https://blog.ha-shem.com/" target="new">Blog</a></Li>
            <Li><Link to="/careers">Careers</Link></Li>
            <Li><Link to="/support">Support</Link></Li>
            <Li><Link to="/terms">Terms of Use</Link></Li>
            <Li><Link to="/policies">Privacy Policy</Link></Li>
          </Ul>
        </ResponsiveDiv>
        <ResponsiveDiv className="py-5">
          <Header>Reach Us</Header>
          <div className="flex flex-col space-y-6 mt-8">
            <div
              className="inline-flex details"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Mail className="w-5 h-5 mr-3" color="white" />
              <P>Email: sales@ha-shem.com</P>
            </div>

            <div
              className="inline-flex details"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Phone className="w-5 h-5 mr-3" color="white" />
              <P><a href="tel:+234 1 4546937">+234 1 4546937</a>, <a href="tel:+234 9087393110">+234 9087393110</a></P>
            </div>

            <div
              className="inline-flex details"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Location className="w-5 h-5 mr-3" color="white" />
              <P>9, Ibikunle Street, off Herbert Macaulay Way, Yaba, Lagos.</P>
            </div>
          </div>
        </ResponsiveDiv>
      </FooterDiv>
      <div className=" flex justify-center items-center copywrite">
        © Ha-Shem Limited {new Date().getFullYear()}. All Rights Reserved.
      </div>
    </StyledFooter>
  );
}

export default Footer;
