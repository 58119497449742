import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Cancel } from "../../assets";
import { useMenu } from "../../context";
import Feedback from "./Feedback";
import { RegularButton, IconWrapper, Backdrop } from "./index";
import { socialLinks, routes } from "./NavBar";

const Wrapper = styled.div`
  display: none;

  @media screen and (max-width: 1190px) {
    display: block;
    height: 100%;
    position: fixed;
    background: #fdfdfd;
    top: 0;
    right: -340px;
    width: 340px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.8);
    transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    z-index: 200;
    overflow-y: scroll;

    &.active {
      right: 0;
      transition: all 300ms ease;
      -o-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
    }
  }
`;
const Ul = styled.ul`
  list-style: none;
`;

const Li = styled.li`
  font-size: 24px;
  font-weight: 500;
  color: ${({color}) => color};
`;

const HavisButtonWrapper = styled.div`
  background: #f8fcff;
  padding: 60px;
`;

const activeColor = "#123F96";

function DrawNavigation() {
    let location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useMenu();

    const handleLoad = () => {
        setIsMenuOpen(false);
    };
    return (
        <Fragment>
            <Wrapper className={isMenuOpen && "active"}>
                <div className="flex flex-col  py-4">
                    <div className="px-8 py-8" onClick={() => setIsMenuOpen((prev) => !prev)}>
                        <div className="h-8">
                            <Cancel/>
                        </div>
                    </div>
                    <HavisButtonWrapper className="flex justify-center items-center">
                        <RegularButton
                            bg="#000066"
                            color="#FFFFFF"
                            height="60px"
                            width="150px"
                            className="text-lg"
                            fontSize="24px"
                        >
                            Havis 360
                        </RegularButton>
                    </HavisButtonWrapper>
                    <div className="pt-8">
                        <Ul className="space-y-3 pl-10">
                            {
                                routes.map(({path, name}) => (
                                    <Li
                                        key={name}
                                        className="ml-0"
                                        to={path}
                                        onClick={handleLoad}
                                        color={location.pathname === path ? activeColor : "#000000"}
                                    >
                                        {
                                            name === "Blog" ?
                                                <a href={path} target="new">{name}</a>
                                                :
                                                <Link
                                                    to={path}
                                                >
                                                    {name}
                                                </Link>
                                        }

                                    </Li>
                                ))
                            }
                        </Ul>

                        <div className="flex py-10 justify-center items-center">
                            {
                                socialLinks.map((socialLink, index) => {
                                    return (
                                        <IconWrapper key={`${index}-drawer`} bg="rgba(18, 63, 150, 0.3)"
                                                     link={socialLink.link}>
                                            <socialLink.component className="w-5 h-5" color="#123F96"/>
                                        </IconWrapper>
                                    );
                                })
                            }
                            <Feedback />
                        </div>
                    </div>
                </div>
            </Wrapper>
            <Backdrop/>
        </Fragment>
    );
}

export default DrawNavigation;
