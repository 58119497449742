import React from "react";
import Select, { components } from "react-select";
import { DropdownIcon } from "../../assets";


const Placeholder = props => {
  return <components.Placeholder {...props} />;
};

const CaretDownIcon = ({isFocused}) => {
  const style = {
    transform: isFocused ? 'rotate(0deg)' : 'rotate(180deg)'
  };
  return <img src={DropdownIcon} alt="Caret" style={style} />;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon isFocused={props.isFocused} />
    </components.DropdownIndicator>
  );
};

function SelectDropdown({selectOptions, placeholderText, controlStyle, value, onChange}) {
  return (
    <div className="App">
      <Select
        closeMenuOnSelect={true}
        components={{ Placeholder, DropdownIndicator, IndicatorSeparator: () => <hr /> }}
        placeholder={placeholderText}
        styles={{
          container: base => ({
            ...base,
            height: '100%',
          }),
          placeholder: base => ({
            ...base,
            fontSize: "1em",
            fontWeight: 400
          }),
          control: base => ({
            ...base,
           ...controlStyle
          }),
          valueContainer: base => ({
            ...base,
            height: '100%'
          }),
          indicatorsContainer: base => ({
            ...base,
            height: '100%'
          })
        }}
        options={selectOptions}
        value={value ? {
          value,
          label: value
        } : null}
        onChange={(event) => onChange(event)}
      />
    </div>
  );
}

export default SelectDropdown;
