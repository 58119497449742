import React from "react";
import styled from "styled-components";

const IconBackground = styled.div`
  background: ${({bg}) => bg || "rgba(11, 13, 23, 0.1)"};
  color: ${(props) => props.color || "#ffffff"};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`;
function IconWrapper({ children, bg, color, link="#" }) {
  return (
    <IconBackground bg={bg} color={color} className="flex-row flex justify-center items-center">
      <a href={link}
         target="_blank"
         rel='noopener noreferrer'>
        {children}
      </a>
    </IconBackground>
  );
}

export default IconWrapper;
