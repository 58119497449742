import React from "react";
import styled from "styled-components";

const ImageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({backgroundImage}) =>
      `url(${backgroundImage}),rgba(7, 75, 154, 0.7);`};
  object-fit: cover;
  background: no-repeat center;
  color: #ffffff;
  font-size: 19px;
  line-height: 28.5px;
`;

function Card({ children, backgroundImage, className }) {
  return (
    <ImageOverlay className={className} backgroundImage={backgroundImage}>
      {children}
    </ImageOverlay>
  );
}

export default Card;
