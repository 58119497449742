import {
  FaTwitter as Twitter,
  FaYoutube as Youtube,
  FaInstagram as Instagram,
  FaMobileAlt as Phone,
  FaFacebookF as Facebook,
  FaLinkedinIn as LinkedIn,
} from "react-icons/fa";

import {
  GoLocation as Location,
  MdMail as Mail,
  MdClose as Cancel,
} from "react-icons/all";
import PreLoader from "./Logo_2.png";
import Loader from "./loader.svg";
import Cool from "./cool.png";
import Disappointed from "./disappointed.png";
import Love from "./love.png";
import Frown from "./frown.png";
import Github from "./github.png";
import iso_2018 from './privacy/2018.png';
import iso_2013 from './privacy/2013.png';
import iso_2015 from './privacy/2015.png';
import iso_2019 from './privacy/2019.png';
import ndpr from './privacy/ndpr.png';


const baseUrl = "https://hashem-website.azureedge.net/ha-shemwebsite";

const Logo = `${baseUrl}/logo.png`;
const Advisory = `${baseUrl}/services/advisory.jpg`;
const Deployment = `${baseUrl}/services/deployment.jpg`;
const Assessment = `${baseUrl}/services/assessment.jpg`;
const Support = `${baseUrl}/services/support.jpg`;
const Training = `${baseUrl}/services/training.jpg`;
const AppDev = `${baseUrl}/services/appDev.png`;
const Backup = `${baseUrl}/services/backup.png`;
const BusinessApp = `${baseUrl}/services/businessApp.png`;
const Modern = `${baseUrl}/services/modern.png`;
const Cloud = `${baseUrl}/services/cloud.png`;
const Comm = `${baseUrl}/services/comm.png`;
const Design = `${baseUrl}/services/design.png`;
const Network = `${baseUrl}/services/network.png`;
const Security = `${baseUrl}/services/security.png`;
const Outsourcing = `${baseUrl}/services/outsourcing.png`;

const PowerAgents = `${baseUrl}/services/PowerAgents.png`;
const PowerApps = `${baseUrl}/services/PowerApps.png`;
const PowerAutomate = `${baseUrl}/services/PowerAutomate.png`;
const PowerBI = `${baseUrl}/services/PowerBI.png`;

const BannerImage = `${baseUrl}/bannerImage.svg`;
const BannerSpheres = `${baseUrl}/bannerSpheres.svg`;

const Kaspersky = `${baseUrl}/partners/kaspersky-logo.png`;
const McAfee = `${baseUrl}/partners/mcafee-logo.png`;
const Oracle = `${baseUrl}/partners/oracle-logo.png`;
const Sap = `${baseUrl}/partners/sap-logo.png`;
const Snom = `${baseUrl}/partners/Snom-logo.png`;
const AdobeReseller = `${baseUrl}/partners/adobe-reseller-logo.png`;
const AutoDeskReseller = `${baseUrl}/partners/autodesk-reseller-logo.png`;
const Castle = `${baseUrl}/partners/castle-logo.png`;
const Certiport = `${baseUrl}/partners/Certiport-logo.png`;
const Corel = `${baseUrl}/partners/corel-logo.png`;
const MicrosoftGold = `${baseUrl}/partners/microsoft-gold.png`;
const Pan = `${baseUrl}/partners/PAN-logo.jpg`;
const PearsonVue = `${baseUrl}/partners/pearsonvue-logo.jpg`;
const Polycom = `${baseUrl}/partners/Polycom-logo.png`;
const PSI = `${baseUrl}/partners/PSI-Logo.jpg`;
const SolarWinds = `${baseUrl}/partners/solarwinds-partner-logo.png`;
const Symantec = `${baseUrl}/partners/Symantec-logo.png`;
const Veam = `${baseUrl}/partners/Veeam-logo.png`;
const Cisco = `${baseUrl}/partners/cisco.png`;
const Sophos = `${baseUrl}/partners/sophos.png`;
const Veritas = `${baseUrl}/partners/veritas-logo.png`;
const Kemp = `${baseUrl}/partners/Kemp_logo.png`;
const Azure = `${baseUrl}/partners/azure.png`;
const Dynamic = `${baseUrl}/partners/dynamic.png`;
const BabylonCloud = `${baseUrl}/partners/babylon-cloud.png`;
const TerranovaSecurity = `${baseUrl}/partners/teranova-security.png`;

const About_laptop = `${baseUrl}/about_laptop.jpg`;
const Quotes = `${baseUrl}/quote.svg`;
const AIICOLogo = `${baseUrl}/clients/aiico.png`;
const Coronation = `${baseUrl}/clients/coronation.png`;
const Heritage = `${baseUrl}/clients/heritage.png`;
const Mutual = `${baseUrl}/clients/mutual.png`;
const TSL = `${baseUrl}/clients/tsl.png`;
const MainOne = `${baseUrl}/clients/mainone-blue.png`;
const AIICO = `${baseUrl}/clients/AIICO_Logo.jpg`;
const Axxela = `${baseUrl}/clients/Axxela_logo_2x.png`;
const Ibom_Air = `${baseUrl}/clients/ibom-air-logo_small_trans.png`;
const Tangerine_Life = `${baseUrl}/clients/tangerine_life.PNG`;
const AccessBankLiberia = `${baseUrl}/clients/accessBankLiberia.png`;
const CBN = `${baseUrl}/clients/cbn.png`;
const Interswitch = `${baseUrl}/clients/interswitch.png`;
const Lamata = `${baseUrl}/clients/lamata.png`;
const ICGC = `${baseUrl}/clients/icgc.png`;
const LPRC = `${baseUrl}/clients/lprc.png`;
const NigerDelta = `${baseUrl}/clients/nigerdelta.png`;
const Etranzact = `${baseUrl}/clients/etranzact.png`;
const LogoTwo = `${baseUrl}/Logo_2.png`;

const DriveBusinessProductivity = `${baseUrl}/products-and-services/drive-business-productivity.jpg`;

const Learn = `${baseUrl}/knowledge/learn.jpg`;
const Certification = `${baseUrl}/knowledge/certification.jpg`;
const Solution = `${baseUrl}/knowledge/solution.jpg`;

const SmallSphere = `${baseUrl}/counts/smallSphere.png`;
const BigSphere = `${baseUrl}/counts/bigSphere.png`;
const MobileBannerSVG = `${baseUrl}/mobileBannerImage.svg`;
const MailingImage = `${baseUrl}/mailing-list/mailing.jpg`;
const Image4 = `${baseUrl}/image4.png`;
const Image5 = `${baseUrl}/image5.png`;
const Vision = `${baseUrl}/vision.png`;
const Values = `${baseUrl}/values.png`;
const Mission = `${baseUrl}/mission.png`;
const AboutBanner = `${baseUrl}/aboutBanner.jpg`;
const Laptop = `${baseUrl}/laptop.PNG`;
const DedicatedMember = `${baseUrl}/dedicatedMember.jpg`;
const Happy = `${baseUrl}/career/happy.jpg`;
const CareerBanner = `${baseUrl}/career/careerBanner.jpg`;
const Devices = `${baseUrl}/devices.jpg`;
const DropdownIcon = `${baseUrl}/drop-icon.png`;

const AdobePremiere = `${baseUrl}/adobe/AdobePremiere.png`;
const AdobeAcrobat = `${baseUrl}/adobe/AdobeAcrobat.png`;
const AdobeCreative = `${baseUrl}/adobe/AdobeCreativeCloud.png`;
const AdobeDocSign = `${baseUrl}/adobe/AdobeDocSign.png`;
const AdobeDocCloud = `${baseUrl}/adobe/AdobeDocumentCloud.png`;
const AdobePhotoshop = `${baseUrl}/adobe/AdobePhotoshop.png`;
const AdobeReader = `${baseUrl}/adobe/AdobeReader.png`;
const AecLogo = `${baseUrl}/adobe/aec-logo.png`;
const ContactUsImage = `${baseUrl}/contact.png`;
const Back = `${baseUrl}/Back.png`;
const Error = `${baseUrl}/Error.png`;

export {
  Facebook,
  Logo,
  Instagram,
  Twitter,
  Youtube,
  LinkedIn,
  Location,
  Mail,
  Phone,
  Cancel,
  Back,
  Error,
  PowerAgents,
  PowerApps,
  PowerAutomate,
  PowerBI,
  DropdownIcon,
  Devices,
  Training,
  Advisory,
  Deployment,
  Assessment,
  Support,
  About_laptop,
  Laptop,
  Learn,
  Certification,
  Modern,
  Solution,
  Quotes,
  AIICOLogo,
  Coronation,
  Heritage,
  Mutual,
  TSL,
  MainOne,
  AIICO,
  Axxela,
  Ibom_Air,
  Tangerine_Life,
  AccessBankLiberia,
  CBN,
  Interswitch,
  Lamata,
  ICGC,
  LPRC,
  NigerDelta,
  Etranzact,
  BannerImage,
  AdobeReseller,
  AutoDeskReseller,
  Castle,
  Certiport,
  Corel,
  MicrosoftGold,
  Pan,
  PearsonVue,
  Polycom,
  PSI,
  Symantec,
  Veam,
  PreLoader,
  Cisco,
  SolarWinds,
  Kaspersky,
  McAfee,
  Oracle,
  Sap,
  Kemp,
  Sophos,
  Veritas,
  Snom,
  Azure,
  Dynamic,
  LogoTwo,
  BannerSpheres,
  SmallSphere,
  BigSphere,
  MobileBannerSVG,
  Image4,
  Image5,
  Vision,
  MailingImage,
  Mission,
  Values,
  AboutBanner,
  AppDev,
  Backup,
  BusinessApp,
  Cloud,
  Comm,
  Design,
  Network,
  Security,
  Happy,
  DedicatedMember,
  CareerBanner,
  DriveBusinessProductivity,
  Outsourcing,
  AdobePremiere,
  AdobeAcrobat,
  AdobeCreative,
  AdobeDocSign,
  AdobeDocCloud,
  AdobePhotoshop,
  AdobeReader,
  AecLogo,
  ContactUsImage,
  BabylonCloud,
  TerranovaSecurity,
  Loader,
  Frown,
  Love,
  Cool,
  Disappointed,
  Github,
  iso_2013,
  iso_2015,
  iso_2018,
  iso_2019,
  ndpr
};
