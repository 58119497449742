import styled from "styled-components";
import { Loader as LoaderSVG } from "../../assets";

const StyledImage = styled.img`
    height: 50px;
    object-fit: fill;
`;

const Loader = () => {
    return <StyledImage className="rounded-lg ml-4" src={LoaderSVG} alt="Loader" />
};

export default Loader;