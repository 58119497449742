import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Cancel } from "../../assets";

const CookiesDiv = styled.div`
  .cookies--backdrop {
    background: #0D0D0D;
    width: 100%;
    min-height: 500px;
    height: 100%;
    z-index: 22214748364 !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    -webkit-transition: all 400ms;-moz-transition: all 400ms;-ms-transition: all 400ms;-o-transition: all 400ms;transition: all 400ms;
  }

  .cookies--container--content {
    z-index: 22214748364 !important;
    color: #FFFFFF;
    background-color: #222222;
    position: fixed;
    bottom: 0;
    padding: 2rem;
    width: 100%;

    p {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;

      .link--privacy {
        text-decoration: underline;
      }
    }

    .cancel--policy {
      position: absolute;
      top: 15px;
      right: 30px;
      cursor: pointer;
    }
  }`;

function Cookies(props) {


    const setCookiesInBrowser = (cookieName, cookieValue, yearToExpire) => {
        let date = new Date();
        date.setFullYear(date.getFullYear() + yearToExpire);
        document.cookie = `${cookieName}=${cookieValue}; expires=${date.toUTCString()};`;
    };

    const closeCookiesBanner = () => {
        const date = new Date();
        setCookiesInBrowser("consentCookieClosed", date.toString(), 1);
        props.setIsCookieSet(true)
    };

    return (
        <CookiesDiv>
            <div className='cookies--backdrop'>
            </div>
            <div className='cookies--container--content'>
                <Cancel className='cancel--policy' onClick={closeCookiesBanner}/>
                <p>
                    We use cookies to enhance your activities and preferences on our website and to make your visit to
                    the
                    site efficient. By browsing our website, you consent to our use of cookies. <br/> For more details,
                    please
                    read our <span className="link--privacy"><Link to='/policies'>Privacy Policy</Link></span>
                </p>
            </div>
        </CookiesDiv>
    );
}

export default Cookies;
