import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { RiMenu3Line } from "react-icons/ri";
import { RegularButton, IconWrapper, DrawNavigation } from "../common";
import {
    Logo,
    Instagram,
    Youtube,
    Facebook,
    Twitter,
    LogoTwo,
    LinkedIn
} from "../../assets";
import { useMenu } from "../../context";
import Feedback from "./Feedback";

export const socialLinks = [
    {
        link: "https://www.linkedin.com/company/ha-shem",
        component: LinkedIn
    },
    {
        link: "https://instagram.com/hashemlimited",
        component: Instagram
    },
    {
        link: "https://web.facebook.com/HashemLimited/",
        component: Facebook
    },
    {
        link: "https://twitter.com/HaShemLimited",
        component: Twitter
    },
    {
        link: "https://www.youtube.com/channel/UCTD9UofKtL4H2_W1RVQ-naQ",
        component: Youtube
    },
];

export const routes = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "About Us",
        path: "/about-us"
    },
    {
        name: "Product & Services",
        path: "/services"
    },
    {
        name: "Blog",
        path: "https://blog.ha-shem.com/"
    }
];

const Nav = styled.nav`
  background-color: ${({bgColor}) => bgColor};
  color: ${({color}) => color || "#ffffff"};
  z-index: 100;
  position: fixed;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  top: 0;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  left: 0;
  right: 0;

  .font-normal {
    font-weight: 300;
  }

  &.sticky-navbar {
    -webkit-box-shadow: 0 8px 6px -9px #999;
    -moz-box-shadow: 0 8px 6px -9px #999;
    box-shadow: 0 8px 6px -9px #999;
  }

  .nav-block {
    @media screen and (max-width: 1190px) {
      display: none;
    }
  }
`;

const LogoImage = styled.img`
  object-fit: contain;
  width: 150px;
  height: 80px;
`;

const NavMenu = styled.div`
  color: blue;
  @media screen and (max-width: 1190px) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  color: ${({color}) => color || "#ffffff"};

  &:hover {
    color: #123F96;
  }
`;

export const NavAnchor = styled.a`
  color: ${({color}) => color || "#ffffff"};

  &:hover {
    color: #123F96;
  }
`;

const MobileBars = styled(RiMenu3Line)`
  display: none;
  @media screen and (max-width: 1190px) {
    display: block;
  }
`;

const INITIAL_NAV_STATE = {
    logoState: "initial",
    textColor: "#ffffff",
    buttonColor: "#ffffff",
    buttonTextColor: "#0d0d0d",
    iconColor: "#ffffff",
    IconBackground: "rgba(11, 13, 23, 0.1)",
};

const activeColor = "#123F96";

const ALTERNATE_NAV_STATE = {
    logoState: "alternate",
    textColor: "black",
    buttonColor: "#000066",
    buttonTextColor: "white",
    iconColor: "#123F96",
    IconBackground: "rgba(18, 63, 150, 0.3)",
};

function NavBar() {
    let location = useLocation();
    const [, setIsMenuOpen] = useMenu();
    const [navBarState, setNavBarState] = useState(INITIAL_NAV_STATE);
    const [isScrolled, setIsScrolled] = useState(false);

    const scrollEventListener = () => {
        if (window.scrollY > 20) {
            setNavBarState(ALTERNATE_NAV_STATE);
            setIsScrolled(true);
        } else {
            setNavBarState(INITIAL_NAV_STATE);
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        scrollEventListener();
        if (location.pathname === "/") {
            document.addEventListener("scroll", scrollEventListener);

            return () => {
                document.removeEventListener("scroll", scrollEventListener);
            };
        } else {
            setNavBarState(ALTERNATE_NAV_STATE);
            setIsScrolled(true);
        }

    }, [location.pathname]);

    const {pathname} = useLocation();
    if (pathname === '/terms' && (window.location !== window.parent.location)) {
        return null;
    }


    return (
        <Fragment>
            <Nav className={`flex justify-between px-4 md:px-20 ${isScrolled && "sticky-navbar"}`}
                 bgColor={isScrolled ? "rgba(255, 255, 255, 0.9)" : "transparent"}>
                <div>
                    {navBarState.logoState === "initial" ? (
                        <Link to="/">
                            <LogoImage src={Logo} alt="logo"/>
                        </Link>
                    ) : (
                        <Link to="/">
                            <LogoImage src={LogoTwo} alt="logoTwo"/>
                        </Link>
                    )}
                </div>

                <div className="flex flex-row items-center">
                    <MobileBars
                        className="mt-3 mr-5 md:mr-0 h-8 w-8"
                        onClick={() => setIsMenuOpen((prev) => !prev)}
                        color={navBarState.iconColor}
                    />
                    <NavMenu className="inline-flex justify-between items-center font-normal">
                        <div className="mr-2 flex flex-row items-center justify-center">
                            {
                                routes.map(({path, name}) => (
                                    name === "Blog" ?
                                        <NavAnchor key={name} color={navBarState.textColor} className="ml-8 "
                                                   href={path}
                                                   target="new">
                                            {name}
                                        </NavAnchor> :
                                        <NavLink
                                            key={name}
                                            className="ml-8 "
                                            to={path}
                                            color={location.pathname === path && (location.pathname !== "/" || isScrolled) ? activeColor : navBarState.textColor}
                                        >
                                            {name}
                                        </NavLink>
                                ))
                            }
                        </div>

                        <div className="mx-8">
                            <RegularButton
                                bg={navBarState.buttonColor}
                                color={navBarState.buttonTextColor}
                            >
                                <a href="https://www.havis360.com/" target="new">
                                    Havis 360
                                </a>
                            </RegularButton>
                        </div>
                    </NavMenu>
                </div>
                <div className="flex flex-row items-center nav-block">
                    <NavMenu className="font-normal">
                        <div className="flex flex-row items-center space-x-2">
                            {
                                socialLinks.map((socialLink, index) => {
                                    return (
                                        <IconWrapper key={`${index}-navbar`} bg={navBarState.IconBackground}
                                                     link={socialLink.link}>
                                            <socialLink.component className="w-5 h-5" color={navBarState.iconColor}/>
                                        </IconWrapper>
                                    );
                                })
                            }
                        </div>
                    </NavMenu>
                    <Feedback />
                </div>
            </Nav>
            <DrawNavigation/>
        </Fragment>
    );
}

export default NavBar;
