import { createContext, useContext, useState } from "react";

const MenuContext = createContext(null);

const useMenu = () => useContext(MenuContext);

const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <MenuContext.Provider value={[isMenuOpen, setIsMenuOpen]}>
      {children}
    </MenuContext.Provider>
  );
};

export { MenuProvider, useMenu };
