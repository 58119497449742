import React from "react";
import styled from "styled-components";

const SectionDiv = styled.div`
  background: ${({backgroundColor}) => backgroundColor || "#E5E5E5"};
`;
function Section({ children, backgroundColor }) {
  return <SectionDiv backgroundColor={backgroundColor}>{children}</SectionDiv>;
}

export default Section;
